<template>
    <div>
      <el-dialog :title = "title" :visible.sync="visible_dialog" width="80%" :before-close="handleClose">
  
        <common-table :table-data="tableData" :table-columns="tableColumns" :table-button="tableButton"
        @handle-button="selectItem"
        ></common-table>
  
        <!--分页组件 不要删除-->
        <div class="layoutBorder">
            <Pagination :total="queryData.total" :page.sync="queryData.currentPage" @pagination="getList()" :limit.sync="queryData.pageSize"/>
        </div>

      </el-dialog>
    </div>
  </template>
  <script>
  import Pagination from "@/components/Pagination.vue";
  import CommonTable from "@/components/shibo_Common/CommonTable.vue"; // 导入 CommonTable 组件
  export default {
      name: "commonPopSelector",
      props: {
        title:{type: String},
        visible: {
            type: Boolean,
            default:false
        },
        tableData: {
            type: Array,
        },
        tableColumns: Array, // 动态表格列配置
        tableButton: Array //  动态按钮列表
      },
      data() {
        return {
                visible_dialog:null,
                queryData: {
                    tableData: [],
                    currentPage: 1,
                    total: 0,
                    pageCount: 0,
                    pageSize: 10
                },
            };
        },
        watch: {
            visible() {
                this.visible_dialog = this.visible;
            },
        },
      components: { Pagination,CommonTable },
      methods: {
        handleClose() {
            this.$emit("update:visible", false);
            console.log('触发子组件handClose');
        },
        selectItem(item) {
            this.$emit("selectItem", item);
            this.visible_dialog = false;
            this.handleClose();
        },
      }
  };
  </script>