<template>
    <div>
      <el-drawer
        title="AMR故障操作"
        :visible.sync="drawer"
        :direction="'rtl'"
        :before-close="handleClose"
      >
        <div style="width: 90%; margin: 0 auto">
          <el-descriptions
            title="当前选中的维修人员信息"
            v-if="selectItem"
            :column="2"
            direction="vertical"
          >
            <el-descriptions-item label="维修人员姓名">{{
              selectItem.name
            }}</el-descriptions-item>
            <el-descriptions-item label="维修人员ID">{{
              selectItem.id
            }}</el-descriptions-item>
            <el-descriptions-item label="所属部门">{{
              selectItem.partment
            }}</el-descriptions-item>
          </el-descriptions>
  
          <el-button @click="showPopSelector" v-if="!selectItem"
            >选择维修人员</el-button
          >
          <div class="layoutBorder">
            <el-button @click="showPopSelector" v-if="selectItem"
              >重新选择维修人员</el-button
            >
          </div>
  
          <el-divider></el-divider>
  
          <el-button type="success" @click="sumbit" v-if="selectItem">确认无误并生成工单</el-button>
        </div>
      </el-drawer>
  
      <infoHeader :content_name="'工单处理流程'" />
  
      <!-- <el-divider content-position="left">AGV信息</el-divider> -->
  
      <el-descriptions title="异常信息表" direction="vertical" :column="3" border v-if="mockdata">
        <el-descriptions-item label="故障编号">{{
          mockdata.id
        }}</el-descriptions-item>
        <el-descriptions-item label="故障名称信息">{{
          mockdata.name
        }}</el-descriptions-item>
        <el-descriptions-item label="故障等级">{{
          mockdata.level
        }}</el-descriptions-item>

        <el-descriptions-item label="故障类型" :span="1">{{
          mockdata.type
        }}</el-descriptions-item>
        <el-descriptions-item label="故障模块" :span="1">{{
          mockdata.module_type
        }}</el-descriptions-item>


        <el-descriptions-item label="最近一次信息更新时间">{{
          mockdata.update_time
        }}</el-descriptions-item>
  
      </el-descriptions>
      <el-divider content-position="left">处理流程</el-divider>
        <el-steps :active="active" finish-status="success">
            <el-step title="步骤 1"></el-step>
            <el-step title="步骤 2"></el-step>
            <el-step title="步骤 3"></el-step>
        </el-steps>
        <el-button style="margin-top: 12px;" @click="next">下一步</el-button>

      <!-- 弹窗组件 -->
      <CommonPopSelectorVue
        title="运维人员名单"
        :visible.sync="popSelectorVisible"
        :table-data="mockTableData"
        :table-columns="tableColumns"
        :table-button="tableButton"
        @selectItem="handleSelectItem"
      />  

    </div>
  </template>
  
  <script>
  // 载入接口

  // 弹窗选择器
  import CommonPopSelectorVue from "@/components/shibo_Common/CommonPopSelector.vue";

  export default {
    name: "start",
    data() {
      return {
        active:0,
        querydata: null,
        mockdata:{
            id:'1',
            name:'AMR机器人触发脱轨',
            level:'严重',
            type:'软件',
            module_type:'感知模块'
        },
        mockTableData:[
            { name: "梁苏皖", id: 1 , partment:'运维部'},
            { name: "刘德华", id: 2 , partment:'运维部'},
            { name: "胡正东", id: 3 , partment:'  运维部'},
        ],
        tableColumns: [
            // 动态表格列配置
            { prop: "name", label: "姓名" },
            { prop: "id", label: "编号" },
            { prop: "partment",label:"部门"}
            // 其他列配置项
        ],
        tableButton:[
            {name:'选择'},
            {name: '删除'}
        ],
        recordata: [],
        ids: null,
        drawer: false,
        popSelectorVisible: false,
        selectItem: null,
      };
    },
    components: {
      CommonPopSelectorVue
    },
    created() {
      this.equipment_id = this.$route.query.ids;
    },
    methods: {
        sumbit() {

        },
        next() {
            if (this.active++ > 2) this.active = 0;
        },
        handleSelectItem(item) {
            console.log("选中的项目: ", item);
            this.selectItem = item;
        },
        showPopSelector() {
            this.popSelectorVisible = true;
        },
        handleClose(done) {
          done()
        },
        handleOrder(ids){
            this.$router.push({name:'work_Info', query:{
                ids: ids
            }});
        }
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  