<!-- CommonTable.vue -->
<template>
    <div class="common-table">
        <el-table :data="tableData" stripe>
            <!-- 使用动态表格列配置 -->
            <el-table-column
                v-for="(column, index) in tableColumns"
                show-overflow-tooltip
                :key="index"
                :label="column.label"
                :prop="column.prop"
                :width="column.width ? item.width : null"
                :min-width="column.minWidth"
                align="center"
            >
            </el-table-column>
            <!-- 按钮列 -->
            <el-table-column label="操作" fixed="right" align="center" min-width="20%">
                <template slot-scope="scope">
                    <!-- 动态渲染按钮列表 -->
                    <span
                        v-for="item in tableButton"
                        :key="item.label"
                        style="margin: 5px"
                    >
                        <el-button
                            :size="item.size"
                            :type="item.type"
                            @click="handleButtonClick(scope.row, item.action)"
                            >{{ item.label }}</el-button
                        >
                    </span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    props: {
        tableData: Array,
        tableColumns: Array, // 动态表格列配置
        tableButton: Array //  动态按钮列表
    },
    methods: {
        handleButtonClick(row, method) {
            // 处理按钮点击事件
            this.$emit('handle-button', row, method);
            // 在这里可以执行与按钮相关的操作
            // 例如，弹出对话框、执行删除操作等
        }
    }
};
</script>
